import { createSlice } from '@reduxjs/toolkit'

const sportsSlice = createSlice({
  name: 'sports',
  initialState: {
    gamesCategories: {},
    gamesCategoriesLoading: false,
    sports: {},
    sportsLoading: false,
    placeBetLoading: false,
    leagues: {},
    leagueLoading: false,
    events: {},
    eventLoading: false,
    liveEvents: {},
    liveEventLoading: false,
    marketCategories: {},
    marketCategoryLoading: false,
    topLeagues: {},
    topLeaguesLoading: false,
    topEvents: {},
    topEventsLoading: false,
    topEventsMatches: [],
    topEventsMatchesLoading: false,
    results: {},
    resultsLoading: false,
    singleEvent: {},
    singleEventLoading: false,
    upcomingfavorites: {},
    upcomingfavLoading: false,
    livefavorites: {},
    livefavLoading: false,
    countries: {},
    countriesLoading: false,
    eventMarkets: {},
    eventMarketsLoading: false,
    allBets: [],
    myBets: {},
    myBetsLoading: false,
    betDetails: {},
    betDetailsLoading: false,
    cashoutAmount: null,
    cashoutAmountLoading: false,
    cashoutLoading: false,
    sportsSearchFieldValue: '',
    socketTopEventData: [],
    socketUpcomingEventData: [],
    socketUpcomingEventFavoriteData: [],
    socketLiveEventFavoriteData: [],
    socketDetailsPageSingleEvent: [],
    socketJoinedRooms: {
      old: [],
      new: []
    },
    reofferBetLoading: false,
    alternativeStakeLoading: false,
    socketLiveEventData: [],
    cashoutData: {},
    quickBetLoading: [],
    customEvents: {},
    customEventsLoading: false,
    customOds: null,
    customOdsLoading: false,
    isCustomTab: false,
    customBetData: [],
    hotCombos: {},
    isAltStakeOpen: false,
    betlistDetails: {},
    isAltStakeLoading: false,
    cashoutSocketResponse: {}
  },
  reducers: {
    getSportsGameCategoriesStart: (state, action) => {
      state.gamesCategoriesLoading = true
    },
    getSportsGameCategoriesComplete: (state, action) => {
      state.gamesCategoriesLoading = false
      state.gamesCategories = action.payload
    },
    getSportsGameCategoriesFailure: (state, action) => {
      state.gamesCategoriesLoading = false
    },
    getTopEventsMatchesStart: (state, action) => {
      state.topEventsMatchesLoading = true
    },
    getTopEventsMatchesComplete: (state, action) => {
      state.topEventsMatchesLoading = false
      state.topEventsMatches = action.payload
    },
    getTopEventsMatchesFailure: (state, action) => {
      state.topEventsMatchesLoading = false
      state.topEventsMatches = {}
    },
    getSportsStart: (state, action) => {
      state.sportsLoading = true
    },
    getSportsComplete: (state, action) => {
      state.sportsLoading = false
      state.sports = action.payload
    },
    getSportsFailure: (state, action) => {
      state.sportsLoading = false
    },
    getLeagueStart: (state, action) => {
      state.leagueLoading = true
    },
    getLeagueComplete: (state, action) => {
      state.leagueLoading = false
      state.leagues = action.payload
    },
    getLeagueFailure: (state, action) => {
      state.leagueLoading = false
    },
    getEventStart: (state, action) => {
      state.eventLoading = true
      state.events = {}
    },
    getEventComplete: (state, action) => {
      state.eventLoading = false
      state.events = action.payload
    },
    getEventFailure: (state, action) => {
      state.eventLoading = false
    },
    getLiveEventStart: (state, action) => {
      state.liveEventLoading = true
      state.liveEvents = {}
    },
    getLiveEventComplete: (state, action) => {
      state.liveEventLoading = false
      state.liveEvents = action.payload
    },
    getLiveEventFailure: (state, action) => {
      state.liveEventLoading = false
    },
    getMarketCategoryStart: (state, action) => {
      state.marketCategoryLoading = true
    },
    getMarketCategoryComplete: (state, action) => {
      state.marketCategoryLoading = false
      state.marketCategories = action.payload
    },
    getMarketCategoryFailure: (state, action) => {
      state.marketCategoryLoading = false
    },
    getTopLeaguesStart: (state, action) => {
      state.topLeaguesLoading = true
    },
    getTopLeaguesComplete: (state, action) => {
      state.topLeaguesLoading = false
      state.topLeagues = action.payload
    },
    getTopLeaguesFailure: (state, action) => {
      state.topLeaguesLoading = false
    },
    getTopEventsStart: (state, action) => {
      state.topEventsLoading = true
      state.topEvents = {}
    },
    getTopEventsComplete: (state, action) => {
      state.topEventsLoading = false
      state.topEvents = action.payload
    },
    getTopEventsFailure: (state, action) => {
      state.topEventsLoading = false
    },
    getResultsStart: (state, action) => {
      state.resultsLoading = true
    },
    getResultsComplete: (state, action) => {
      state.resultsLoading = false
      state.results = action.payload
    },
    getResultsFailure: (state, action) => {
      state.resultsLoading = false
    },
    getSingleEventStart: (state, action) => {
      state.singleEventLoading = true
    },
    getSingleEventComplete: (state, action) => {
      state.singleEventLoading = false
      state.singleEvent = action.payload
    },
    getSingleEventFailure: (state, action) => {
      state.singleEventLoading = false
    },
    getLiveFavStart: (state, action) => {
      state.liveFavLoading = true
    },
    getLiveFavComplete: (state, action) => {
      state.liveFavLoading = false
      state.livefavorites = action.payload
    },
    getLiveFavFailure: (state, action) => {
      state.liveFavLoading = false
    },
    getUpcomingFavStart: (state, action) => {
      state.upcomingFavLoading = true
    },
    getUpcomingFavComplete: (state, action) => {
      state.upcomingFavLoading = false
      state.upcomingfavorites = action.payload
    },
    getUpcomingFavFailure: (state, action) => {
      state.upcomingFavLoading = false
    },
    getCountriesStart: (state, action) => {
      state.countriesLoading = true
    },
    getCountriesComplete: (state, action) => {
      state.countriesLoading = false
      state.countries = action.payload
    },
    getCountriesFailure: (state, action) => {
      state.countriesLoading = false
    },
    getEventMarketsStart: (state, action) => {
      state.eventMarketsLoading = true
    },
    getEventMarketsComplete: (state, action) => {
      state.eventMarketsLoading = false
      state.eventMarkets = action.payload
    },
    getEventMarketsFailure: (state, action) => {
      state.eventMarketsLoading = false
    },
    toggleFavoriteStart: (state) => ({
      ...state
    }),
    toggleFavoriteSuccess: (state, { payload }) => ({
      ...state
    }),
    toggleFavoriteFailure: (state) => ({
      ...state
    }),
    addBet: (state, action) => {
      state.allBets = action.payload
    },
    placeBetStart: (state, action) => {
      state.placeBetLoading = true
    },
    placeBetComplete: (state, action) => {
      state.placeBetLoading = false
    },
    getMyBetsStart: (state, action) => {
      state.myBetsLoading = true
    },
    getMyBetsComplete: (state, action) => {
      state.myBetsLoading = false
      state.myBets = action.payload
    },
    getMyBetsFailure: (state, action) => {
      state.myBetsLoading = false
    },
    getBetDetailsStart: (state, action) => {
      state.betDetailsLoading = true
    },
    getBetDetailsComplete: (state, action) => {
      state.betDetailsLoading = false
      state.betDetails = action.payload
    },
    getBetDetailsFailure: (state, action) => {
      state.betDetailsLoading = false
    },
    getCashoutAmountStart: (state, action) => {
      state.cashoutAmountLoading = true
      state.cashoutData = {}
      state.cashoutAmount = null
    },
    getCashoutAmountComplete: (state, action) => {
      state.cashoutAmountLoading = false
      state.cashoutAmount = action.payload?.payoutValue
      state.cashoutData = action.payload
    },
    getCashoutAmountFailure: (state, action) => {
      state.cashoutAmountLoading = false
    },
    processCashoutStart: (state, action) => {
      state.cashoutLoading = action?.payload?.betslipId
    },
    processCashoutComplete: (state, action) => {
      state.cashoutLoading = false
    },
    processCashoutFailure: (state, action) => {
      state.cashoutLoading = false
    },
    setSportsSearchedValue: (state, { payload }) => ({
      ...state,
      sportsSearchFieldValue: payload
    }),
    setSocketTopEventData: (state, { payload }) => ({
      ...state,
      socketTopEventData: payload
    }),
    setSocketLiveEventData: (state, { payload }) => ({
      ...state,
      socketLiveEventData: payload
    }),
    setSocketUpcomingEventData: (state, { payload }) => ({
      ...state,
      socketUpcomingEventData: payload
    }),
    setSocketJoinedRooms: (state, { payload }) => ({
      ...state,
      socketJoinedRooms: payload
    }),
    setSocketLiveFavoriteEvents: (state, { payload }) => ({
      ...state,
      socketLiveEventFavoriteData: payload
    }),
    setSocketUpcomingFavoriteEvents: (state, { payload }) => ({
      ...state,
      socketUpcomingEventFavoriteData: payload
    }),
    setSocketDetailsPageSingleEvents: (state, { payload }) => ({
      ...state,
      socketDetailsPageSingleEvent: payload
    }),
    alternativeStakeStart: (state, action) => {
      state.alternativeStakeLoading = true
    },
    alternativeStakeComplete: (state, action) => {
      state.alternativeStakeLoading = false
    },
    alternativeStakeFailure: (state, action) => {
      state.alternativeStakeLoading = false
    },
    reofferBetStart: (state, action) => {
      state.reofferBetLoading = action?.payload?.betslipId
    },
    reofferBetComplete: (state, action) => {
      state.reofferBetLoading = false
    },
    reofferBetFailure: (state, action) => {
      state.reofferBetLoading = false
    },
    quickBetLoadingStatus: (state, action) => {
      state.quickBetLoading = action?.payload
    },
    getCustomEventsStart: (state, action) => {
      state.customEventsLoading = true
    },
    getCustomEventsComplete: (state, action) => {
      state.customEventsLoading = false
      state.customEvents = action.payload
    },
    getCustomEventsFailure: (state, action) => {
      state.customEventsLoading = false
    },
    getCalculatesOdsStart: (state, action) => {
      state.customOdsLoading = true
    },
    getCalculatesOdsComplete: (state, action) => {
      state.customOdsLoading = false
      state.customOds = action.payload
    },
    getCalculatesOdsFailure: (state, action) => {
      state.customOdsLoading = false
    },
    setIsCustomTab: (state, action) => {
      state.isCustomTab = action.payload
    },
    setCustomBetData: (state, action) => {
      state.customBetData = action.payload
    },
    getHotCombosStart: (state, action) => {
      state.hotCombosLoading = true
    },
    getHotCombosComplete: (state, action) => {
      state.hotCombosLoading = false
      state.hotCombos = action.payload
    },
    getHotCombosFailure: (state, action) => {
      state.hotCombosLoading = false
    },
    setAltStakePopupOpen: (state, { payload }) => ({
      ...state,
      isAltStakeOpen: payload
    }),
    altStakeBetSlipStart: (state) => ({
      ...state,
      isAltStakeLoading: true
    }),
    altStakeBetSlipSuccess: (state, { payload }) => ({
      ...state,
      isAltStakeLoading: false,
      betlistDetails: payload
    }),
    altStakeBetSlipFailure: (state) => ({
      ...state,
      isAltStakeLoading: false
    }),
    setCashoutSocketResponse: (state, { payload }) => ({
      ...state,
      cashoutSocketResponse: payload
    })
  }
})
export const {
  setCashoutSocketResponse,
  placeBetStart,
  setIsCustomTab,
  setCustomBetData,
  placeBetComplete,
  getSportsGameCategoriesStart,
  getSportsGameCategoriesComplete,
  getSportsGameCategoriesFailure,
  getSportsStart,
  getSportsComplete,
  getSportsFailure,
  getLeagueStart,
  getLeagueComplete,
  getLeagueFailure,
  getEventStart,
  getEventComplete,
  getEventFailure,
  getMarketCategoryStart,
  getMarketCategoryComplete,
  getMarketCategoryFailure,
  getTopLeaguesStart,
  getTopLeaguesComplete,
  getTopLeaguesFailure,
  getTopEventsStart,
  getTopEventsComplete,
  getTopEventsFailure,
  getResultsStart,
  getResultsComplete,
  getResultsFailure,
  getSingleEventStart,
  getSingleEventComplete,
  getSingleEventFailure,
  getLiveFavStart,
  getLiveFavComplete,
  getLiveFavFailure,
  getUpcomingFavStart,
  getUpcomingFavComplete,
  getUpcomingFavFailure,
  getCountriesStart,
  getCountriesComplete,
  getCountriesFailure,
  toggleFavoriteFailure,
  toggleFavoriteStart,
  toggleFavoriteSuccess,
  getLiveEventStart,
  getLiveEventComplete,
  getLiveEventFailure,
  addBet,
  getEventMarketsStart,
  getEventMarketsComplete,
  getEventMarketsFailure,
  getMyBetsStart,
  getMyBetsComplete,
  getMyBetsFailure,
  getBetDetailsStart,
  getBetDetailsComplete,
  getBetDetailsFailure,
  getCashoutAmountStart,
  getCashoutAmountComplete,
  getCashoutAmountFailure,
  processCashoutStart,
  processCashoutComplete,
  processCashoutFailure,
  setSportsSearchedValue,
  setSocketTopEventData,
  setSocketUpcomingEventData,
  setSocketJoinedRooms,
  reofferBetFailure,
  reofferBetComplete,
  reofferBetStart,
  alternativeStakeFailure,
  alternativeStakeComplete,
  alternativeStakeStart,
  setSocketLiveEventData,
  setSocketUpcomingFavoriteEvents,
  setSocketLiveFavoriteEvents,
  setSocketDetailsPageSingleEvents,
  quickBetLoadingStatus,
  getCustomEventsFailure,
  getCustomEventsComplete,
  getCustomEventsStart,
  getCalculatesOdsStart,
  getCalculatesOdsComplete,
  getCalculatesOdsFailure,
  getHotCombosFailure,
  getHotCombosComplete,
  getHotCombosStart,
  altStakeBetSlipStart,
  altStakeBetSlipSuccess,
  altStakeBetSlipFailure,
  setAltStakePopupOpen,
  getTopEventsMatchesStart,
  getTopEventsMatchesComplete,
  getTopEventsMatchesFailure

} = sportsSlice.actions

export default sportsSlice.reducer

const JAJSON = {
  translation: {
    claimed: '請求済み',
    redTiger: 'レッドタイガー',
    netEnt: 'ネットエンターテインメント',
    bigTimeGaming: 'ビッグタイムゲーミング',
    nolimitCity: 'ノーリミットシティ',
    sorryForInconvenience: 'ご迷惑をおかけして申し訳ございません。',
    maintenaceModeOn: 'メンテナンスモードがオンになっています。',
    maintenaceContent: 'サイトは間もなくご利用いただけるようになります。ご理解いただきありがとうございます。',
    wagered: '賭けた',
    betAmount: '賭け金',
    betSlip: 'ベットスリップ',
    referralCodeInvalid: '無効な紹介コード',
    transactions: '取引',
    rollBonus: 'ロールボーナス',
    rollBonusText: 'ボーナス額を現金額に換算してもよろしいですか?',
    liveSupport: 'ライブサポート',
    from: '送信元',
    to: '送信先',
    selectDate: '日付選択',
    rollingContest: 'ローリングコンテスト',
    event: 'イベント',
    bigWin: 'ビッグウィン',
    referralCodeRequired: '紹介コードが必要です',
    home: '家',
    referralCodePlaceholder: '紹介コードを入力してください。',
    theme: 'テーマ',
    light: '明るい',
    dark: '暗い',
    chooseImage: '画像を選択',
    imageFormatRequired: '画像はjpg、png、webp形式である必要があります',
    imageSize5MB: '画像は5MB以内である必要があります',
    profileImageUploaded: 'プロフィール画像が正常にアップロードされました',
    profileImageRemoved: 'プロフィール画像が正常に削除されました',
    allContents: 'すべてのコンテンツ',
    responsibleGaming: '責任あるギャンブル',
    tenanatAreBlocked: 'テナントがブロックされています。',
    cashAmount: '現金額',
    EMERGENCY: ' 緊急',
    MAINTENANCE: 'メンテナンス',
    Bonus: 'ボーナス',
    WALLET: '財布',
    'UNIQUE-NUMBER-BONUS': 'ユニーク番号ボーナス',
    LIVE: 'ライブ',
    LOSE: ' 負ける',
    playing: '遊ぶ',
    noBonusComingSoon: '近日公開、まだ開始されていません',
    noBonusFound: 'ボーナスは見つかりませんでした',
    bonusTickets: 'ボーナスチケット',
    couponNumber: 'クーポン番号',
    totalTickets: '総チケット数',
    nonCashAmount: 'プロモーションキャッシュウォレット',
    noNotificationFound: '通知はありません',
    notificationTitle: '通知',
    total: '合計',
    vaultAmount: '貯蔵金額',
    vault: '金庫',
    vaultDepositSuccess: '入金成功',
    vaultWithdrawSuccess: '出金成功',
    LOST: '失われた',
    BET: '賭ける',
    WIN: '勝つ',
    blacklistedError: 'ブラックリストに登録されています。ログインが許可されていません。',
    submit: '提出する',
    Active: 'アクティブ',
    InActive: '非アクティブ',
    date: '日付 ',
    action: 'アクション',
    recommendGames: 'おすすめゲーム',
    noProvidersFound: 'プロバイダが見つかりません',
    rank: 'ランク',
    username: 'ユーザー名',
    createdAt: '作成日時',
    status: '状態',
    copy: 'コピーする',
    cancel: 'キャンセル',
    confirm: '確認する',
    copied: 'コピー済み',
    copiedSuccess: '正常にコピーされました',
    referredUsers: '紹介されたユーザー',
    referAUser: 'ユーザーを紹介する',
    referByLink: 'リンクによってユーザーを紹介しました',
    noReferralUsers: '紹介されたユーザーが見つかりませんでした',
    kycNotComplete: '本人確認が保留中または拒否されています。プラットフォームにアクセスするには、まず確認してください',
    checkKycStatus: '本人確認ステータス',
    kycStatusTitle: '本人確認ステータス',
    verified: '確認済み',
    notVerified: '未確認',
    APPROVED: '承認済み',
    REJECTED: '拒否されました',
    otherLinks: 'その他のリンク',
    landingPage: 'ランディングページ',
    supportLinks: 'サポートリンク',
    others: 'その他',
    casinoBets: 'カジノベット',
    sportBets: 'スポーツベット',
    insufficientBalance: '残高不足です！',
    record: '記録',
    data: 'データ',
    oldPasswordDidNotMatch: '古いパスワードが一致しません',
    loading: 'ロード中...',
    submitting: '提出中...',
    hide: '非表示',
    request: 'リクエスト',
    clearAll: 'すべてクリア',
    noFavouritesGames: 'お気に入りリストにゲームが追加されていません。',
    withdrawlPasswordDoesNotMatch: '「引き出しパスワードが一致しません」',
    na: '「該当なし」 or 「なし」',
    ACCEPT: '「承認」',
    PENDING: '「保留中」',
    REJECT: '「拒否」',
    CANCEL: 'キャンセルされました',
    withdrawal: '引き出し',
    prize: '賞品',
    noDeposit: '入金なし',
    noWithdrawl: '引き出しなし',
    verifyUser: 'ユーザーを確認する',
    currencyRequired: '通貨が必要です',
    amountRequired: '金額が必要です',
    selectCurrency: '通貨を選択してください',
    currency: '通貨',
    requestCreatedSuccess: 'リクエストが正常に作成されました',
    walletActionSourceAlreadyExists: '保留中のリクエストはすでに存在しています',
    min1Required: '最低1が必要です',
    positiveNumber: '正の数のみが許可されています',
    logoutContent: '退出する前に、当社の豊富なカジノやスポーツのプロモーションをぜひチェックしてください！',
    success: '成功',
    error: 'エラー',
    warning: '警告',
    hidden: '非表示',
    show: '表示',
    amount: '金額',
    enterAmountPlaceholder: '金額を入力してください',
    requestWithdraw: '引き出しをリクエスト',
    requestDeposit: '入金をリクエスト',
    language: '言語',
    odds: 'オッズ',
    decimal: 'デシマル',
    fractional: '分数',
    support: 'サポート',
    partners: 'パートナー',
    press: 'プレス',
    allRightsReserved: '全著作権所有',
    acceptTerms: '利用規約に同意する',
    footerContent: 'DinoはMedium Rare N.V.によって所有および運営されています。登録番号: 145353、登録住所: Korporaalweg 10、Willemstad、Curaçao。お問い合わせはsupport@dino.comまで。支払い代理会社はMedium Rare Limitedで、登録住所は7-9 Riga Feraiou、LIZANTIA COURT、Office 310、Agioi Omologites、1087 Nicosia、Cyprusで、登録番号: HE 410775です。',

    // Common
    game: 'ゲーム',
    menu: 'メニュー',
    user: 'ユーザー',
    time: '時間',
    hacksaw: 'ハックソー',
    fullScreen: 'フルスクリーン',
    recommended: 'おすすめ',
    addToFavourites: 'お気に入りに追加',
    removeFromFavourites: 'お気に入りから削除',
    addToFavSuccess: 'お気に入りに追加しました」',
    removeFromFavSuccess: 'お気に入りから削除しました」',
    enableTheatre: 'シアターモードを有効にする',
    disableTheatre: 'シアターモードを無効にする',
    pragmatic: 'プラグマティックプレイ',
    evolution: 'エボリューション',
    ezugi: 'エズギ',
    pgsoft: 'PG ソフト',
    payout: '払い戻し',
    viewAllProvider: 'すべてのプロバイダーを表示',
    loadMore: 'さらに読み込む',
    sortBy: '並び替え',
    filterBy: 'フィルター',
    atoz: 'ア - ゼット',
    ztoa: 'ゼット - ア',
    all: 'すべて',
    viewAll: 'すべて表示',
    save: '保存',
    areYouSure: '本当によろしいですか',
    of: 'の',
    myVouchers: 'マイバウチャー',
    noVoucherFound: '利用可能なバウチャーがありません',
    voucherName: 'バウチャー名',
    validFrom: '有効開始日',
    validTo: '有効終了日',
    betTarget: 'ベット対象',
    voucherUseLimit: 'バウチャー使用制限',
    redeem: '交換',
    vouchers: 'バウチャー',
    // headings
    unbeatableDeal: '打ち負かせないディール',
    limitedTimeOffer: '期間限定オファー',
    biggestSale: '史上最大のセール！',
    grabYourSaving: 'あなたの節約を手に入れる',
    newYearDeal: '新年、新しいディール！',
    exploreNow: '今すぐ探索',
    claimNow: '今すぐ請求',
    scratchcard: 'スクラッチカード',
    slot: 'スロット',
    blackjack: 'ブラックジャック',
    'live games': 'ライブカジノ',
    baccarat: 'バカラ',
    roulette: 'ルーレット',
    unknown: '不明',
    hot: '熱い',
    new: '新しい',

    // title and links
    logout: 'ログアウト',
    wallet: 'ウォレット',
    deposit: 'デポジット',
    withdraw: '引き出し',
    setting: '設定',
    changePassword: 'パスワードの変更',
    search: '検索',
    login: 'ログイン',
    casino: 'カジノ',
    sports: 'スポーツ',
    eSports: 'Eスポーツ',
    noSportsAvailable: '利用可能なスポーツはありません',
    favourites: 'お気に入り',
    myBets: '私のカジノベット',
    mySportsBets: '私のスポーツベット',
    allBets: 'トータルベットリーダーボード',
    raceLeaderBoard: 'ビッグウィンリーダーボード',
    raceRollingContestBoard: 'ローリングコンテストのリーダーボード',
    games: 'ゲーム',
    searchYourGame: 'ゲームを検索',
    searchAtleast3Char: '検索には少なくとも2文字必要です。',
    providers: 'プロバイダー',
    forgotPassword: 'パスワードを忘れた',
    profile: 'プロフィール',
    sportsBook: 'スポーツブック',
    comingSoon: '近日公開',
    goToLobby: 'ロビーに移動',
    goToCasino: 'カジノに移動',
    goToSports: 'スポーツに行く',
    casinoTitle: 'トップのオンライン暗号カジノ',
    noCryptoNoProblem: 'クリプトなし？ 問題なし！',
    buyCrypto: 'クリプトを購入',
    casinoPara: 'Dinoは公正で楽しいオンラインギャンブル体験を提供するため、巨大な範囲のカジノゲームをブラウズしてください。ブラウザから直接、スロット、ライブカジノ、ブラックジャック、バカラ、ルーレット、お気に入りのDinoオリジナルを含む何千ものクラシックなカジノゲームをプレイできます。',
    sportsPara: 'Dinoは公正で楽しいオンラインギャンブル体験を提供するため、巨大な範囲のカジノゲームをブラウズしてください。ブラウザから直接、スロット、ライブカジノ、ブラックジャック、バカラ、ルーレット、お気に入りのDinoオリジナルを含む何千ものクラシックなカジノゲームをプレイできます。',
    sportsTitle: '最高の暗号スポーツベッティングオンライン',
    displaying: '表示中',
    errorWhileLoadingGame: 'ゲームの読み込み中にエラーが発生しました',
    hotTimeBonus: 'ホットタイムボーナス',
    compensationBonus: '報酬ボーナス',
    reocurringBonus: '繰り返しボーナス',
    totalWalletAmount: 'ウォレットの合計金額',
    bounsWalletAmount: 'ボーナスウォレット残高',

    // formLabels
    usernameLabel: 'ユーザー名',
    passwordLabel: 'パスワード',
    oldPasswordLabel: '旧パスワード',
    newPasswordLabel: '新しいパスワード',
    confirmPasswordLabel: 'パスワードの確認',
    confirmNewPasswordLabel: '新しいパスワードの確認',
    idOfUserLabel: 'ユーザーID',
    legalNameLabel: '法的な名前',
    nationalityLabel: '国籍',
    dateOfBirthLabel: '生年月日',
    selectMonth: '月を選択',
    selectDay: '日を選択',
    selectYear: '年を選択',
    phoneLabel: '電話',
    preferredCurrencyLabel: '希望通貨',
    bankNameLabel: '銀行名',
    accountNumberLabel: '口座番号',
    withdrawalPasswordLabel: '引き出しパスワード ',
    reEnterwithdrawalPasswordLabel: '引き出しパスワードを再入力 ',

    // SignUp and Account Info
    signIn: 'サインイン',
    signUp: 'サインアップ',
    userIdPlaceholder: 'ユーザーIDを入力',
    passwordRequired: 'パスワードが必要です',
    oldPasswordRequired: '旧パスワードが必要です',
    newPasswordRequired: '新しいパスワードが必要です',
    accountFrozen: 'アカウントは凍結されています',
    userLoggedOut: 'ログアウトしました',
    loggingIn: '送信中、お待ちください...',
    selectNationality: '国籍を選択',
    selectBankName: '銀行名を選択',
    selectPreferredCurrency: '希望通貨を選択',
    dontHaveAccount: 'アカウントがありませんか？',
    registerAnAccount: 'アカウントを登録',
    alreadyHaveAnAccount: 'すでにアカウントをお持ちですか？',
    fillAllFields: 'サインアップする前にすべての必須フィールドを入力してください',
    userNamePlaceholder: 'ユーザー名を入力',
    min8Numbers: '最低8桁の数字が必要です',
    passwordValidationMessage: 'パスワードは8文字以上で、少なくとも1つの大文字、1つの数字、および1つの特殊文字を含める必要があります。スペース、ドット、コンマは使用できません',
    withdrawlPasswordValidationMessage: '出金用パスワードは8文字以上で、少なくとも1つの大文字、1つの数字、および1つの特殊文字を含める必要があります。スペース、ドット、コンマは使用できません',
    passwordPlaceholder: 'パスワードを入力',
    confirmPasswordPlaceholder: '確認用パスワードを入力',
    passwordWithdrawalPlaceholder: '引き出しパスワードを入力',
    oldPasswordPlaceholder: '旧パスワードを入力',
    newPasswordPlaceholder: '新しいパスワードを入力',
    confirmNewPasswordPlaceholder: '新しいパスワードの確認を入力',
    confirmPasswordWithdrawalPlaceholder: '引き出しパスワードを再入力',
    legalNamePlaceholder: '法的な名前を入力',
    phonePlaceholder: '電話番号を入力',
    searchPhonePlaceholder: '国を検索',
    accountNumberPlaceholder: '口座番号を入力',
    userIdRequired: 'ユーザーIDが必要です',
    userIdInvalid: '半角英数字のみ受け付けられます',
    confirmPasswordRequired: '確認用パスワードが必要です',
    confirmNewPasswordRequired: '新しいパスワードの確認が必要です',
    passwordWithdrawalRequired: '引き出しパスワードが必要です',
    confirmPasswordWithdrawalRequired: '引き出しパスワードの確認が必要です',
    legalNameRequired: '法的な名前が必要です',
    phoneRequired: '電話番号が必要です',
    dateOfBirthRequired: '生年月日が必要です',
    userNameRequired: 'ユーザー名が必要です',
    requiredAccountNumber: '口座番号が必要です',
    countryRequired: '国が必要です',
    matchPassword: 'パスワードが一致している必要があります',
    matchWithdrawalPassword: '引き出しパスワードが一致している必要があります',
    onlyAlphabet: 'アルファベットのみが許可されています',
    mustBe18: '登録するには18歳以上である必要があります',
    validDOB: '有効な生年月日を入力してください',
    max20Characters: '最大20文字が許可されています',
    min3Characters: '最小3文字が必要です',
    min2Characters: '최소 2자 이상이 필요합니다',
    min4Characters: '最小4文字が必要です',
    min5Numbers: 'ダイヤルコードを含む最小5桁が必要です',
    spaceNotAllowed: 'スペース、ドット、カンマは許可されていません',
    onlySpaceNotAllowed: 'スペースは許可されていません',
    max100Characters: '最大100文字が許可されています',
    max50Characters: '最大50文字が許可されています',
    max30Characters: '最大30文字が許可されています',
    max16Characters: '最大16文字が許可されています',
    max16Numbers: 'ダイヤルコードを含む最大16桁が許可されています',
    preferredLanguageRequired: '希望言語が必要です',
    preferredCurrencyRequired: '希望通貨が必要です',
    bankNameRequired: '銀行名が必要です',
    nationalityRequired: '国籍が必要です',
    legalName: 'アルファベットとスペースのみが許可されています',
    legalNameOneSpace: '単語間には1つのスペースのみが許可されています',
    selectPreferredLanguage: '希望言語を選択',
    enterValidPhone: '有効な電話番号を入力してください',
    userNameAllowed: '小文字のアルファベットのみが許可され、スペースは許可されていません',
    VOUCHER: 'バウチャー',

    // error
    userNameAlreadyExists: 'ユーザー名はすでに存在しています！',
    incorrectCredentials: '指定されたパスワードのユーザー名は存在しません！',
    UserCountryCodeWithPhoneAlreadyExists: 'この電話番号を持つユーザー名はすでに存在しています！',
    loginErrorMessage: '続行するにはログインしてください',

    pageNotFound: 'ページが見つかりません',
    suggestToBackHome: 'ホームに戻ることをお勧めします',
    noGames: 'ゲームが見つかりません',
    noEvents: '試合は利用できません',
    oopps: 'おっと」',
    somethingNotWorking: '申し訳ありません、ここで何かがうまく機能していません！',
    remainingRedeemptions: '残りの交換回数',

    // ErrorMessages
    noDataFound: 'データが見つかりません',
    noCategory: 'カテゴリが見つかりません',

    // Reset/Forgot Password
    setNewPass: '新しいパスワードを設定',

    // Toast
    loginSuccess: 'ログインが成功しました',
    signupSuccess: 'サインアップが成功しました',
    updatedSuccess: '更新が成功しました',

    // Cookie Consent
    cookieTitle: 'クッキー',
    declineButton: '拒否',
    acceptButton: '同意',
    cookieDeclinedMessage: '最高のエクスペリエンスのためには、このウェブサイトを続けるためにはクッキーを受け入れる必要があります。続行する場合はクッキーを受け入れてください。',
    cookieDescription: 'dino.comへようこそ！私たちはクッキーを使用してユーザーエクスペリエンスを最適化します。\'同意\'をクリックすることで、その使用に同意することになります。\'拒否\'をクリックしてオプトアウトできます。',
    forgetPasswordRequest: 'パスワードをお忘れですか?',
    forgetPassword: 'パスワードをお忘れですか',
    forgetPasswordRequestSent: 'パスワードを忘れた場合のリクエストが送信されました',
    itWillbeYourUpdatedWithdrawalPasswordAsWell: 'これは更新された出金パスワードとしても使用されることに注意してください。',
    newLevelUnlock: '新しいレベルのロック解除',
    newSeasonUnlock: '新しい季節のロックを解除する',
    vouchersAndGiftcards: 'バウチャーとギフトカード',
    giftCards: 'ギフトカード',
    availableRedeemptions: '利用可能な交換回数',
    noGiftCardFound: '利用可能なギフトカードがありません',
    joinContest: 'コンテストに参加する',
    contestName: 'コンテスト名 ',
    joincontestContent: '知恵を賭けて、運命を勝ち取れ – 挑戦する勇気があるなら入ってみて！',
    yes: 'はい',
    no: 'いいえ',
    registeredForRollingContest: 'ゲームの準備が整いました！あなたは公式に登録されました！',
    alreadyRegister: '既に登録されています!!',
    noContestSchedule: 'コンテストの予定はありません',
    playerName: 'プレイヤー名',
    leadAmount: 'リード金額',

    // ********** SportsBook Translations ********************
    noHotComboAvailable: '現在、ホットコンボは利用できません',
    pleaseEnterStake: '賭け金を入力してください',
    single: 'シングル',
    combo: 'コンボ',
    noBetsAdded: 'ベットは追加されていません',
    possibleWin: '可能な勝利',
    placeBet: 'ベットをする',
    clearBetslip: 'ベットスリップをクリア',
    vs: '対',
    liveEvents: 'ライブイベント',
    topEvents: 'トップイベント',
    upcomingEvents: '今後のイベント',
    highlight: 'ハイライト',
    schedule: 'スケジュール',
    live: 'ライブ',
    favorite: 'お気に入り',
    myBet: 'マイベット',
    result: '結果',
    prev: '前へ',
    next: '次へ',
    today: '今日',
    Tomorrow: '明日',
    favorites: 'お気に入り',
    selectSport: 'スポーツを選択',
    selectCountry: '国を選択',
    selectLeague: 'リーグを選択',
    fromDate: '開始日',
    toDate: '終了日',
    winAmount: '勝利金額',
    stake: '賭け金',
    lostAmount: '損失額',
    cashoutAmount: 'キャッシュアウト額',
    pending: '保留中',
    rejected: '拒否されました',
    cashoutButton: 'キャッシュアウト',
    betSlipId: 'ベットスリップID',
    in_game: 'ゲーム中',
    cashout: 'キャッシュアウト',
    won: '勝利',
    lost: '敗北',
    refund: '払い戻し',
    partial_won: '一部勝利',
    topLeague: 'トップリーグ',
    noLeagues: '利用可能なリーグはありません',
    alternativeOddType: 'オルタナティブオッズタイプ',
    areYouSureCashout: 'キャッシュアウトを行いますか？',
    alternateStakeButton: '代替賭け金',
    reOfferButton: 'ベット再オファー',
    alternativeStakeTitle: '代替賭け金オファー',
    viewAllMarket: 'すべての市場を見るにはここをクリック',
    closed: '閉鎖',
    removeClosedBet: 'ベットスリップから閉じたベットを削除してください！',
    betSetting: 'オッズ設定',
    any: 'すべてのオッズ変更を受け入れる',
    none: 'オッズ変更を受け入れない',
    higher: 'より高いオッズ変更を受け入れる',
    reset: 'リセット',
    selectFromDate: '開始日を選択',
    selectToDate: '終了日を選択',
    customBet: 'カスタムベット',
    calclutedOds: '計算されたオッズ',
    removeMainBet: 'ベットスリップからメインベットを先に削除してください',
    removeCustomBet: 'ベットスリップからカスタムベットを先に削除してください',
    addSameCusomMarket: '同じカスタムマーケットイベントを追加してください',
    enterStake: '賭け金を入力してください',
    main: 'メイン',
    custom: 'カスタム',
    quickBet: 'クイックベット',
    quickBetDesc: 'クイックベットモードがオンになっています！任意の選択をシングルクリックすると、即座にベットが行われます。すべてのベットを確認するには',
    page: 'ページ',
    quickBetIsActiveWith: 'クイックベットは次の状態でアクティブです',
    pleaseEnterStakeToActiveBet: 'ベットをアクティブにするには賭け金を入力してください',
    loginToPlaceBet: 'ベットを行うにはログインしてください',
    habanero: 'ハバネロ',
    tie: '引き分け',
    addAtleasetTwoEvents: 'ベットを行うには少なくとも2つのカスタムイベントを追加してください',
    events: 'イベント',
    noEventsSchedule: 'イベントの予定はありません',
    bonus: 'ボーナス',
    reoccuringBonus: '繰り返しボーナス',
    firstDepositBonus: '初回入金ボーナス',
    noBonus: 'ボーナスなし',
    bonusLapsed: 'ボーナス失効',
    bonusRollProgress: 'ボーナスロールの進行状況',
    'HOT-TIME-BONUS': 'ホットタイムボーナス',
    'COMPENSATION-BONUS': '補償ボーナス',
    'RE-OCCURING-DEPOSIT-BONUS': '定期入金ボーナス',
    'FIRST-DEPOSIT-BONUS': '初回入金ボーナス',
    'UNIQUE-TICKET-BONUS': 'ユニークチケットボーナス',
    notActive: '非アクティブ',
    active: 'アクティブ',
    clickToViewBonusProgress: 'ボーナスの進行状況を見るにはクリック',
    depositMoneyGetBonusBenefits: 'ボーナス特典を得るためにお金を預ける',
    bonusDetails: 'ボーナスの詳細',
    gamble: 'ギャンブル',
    poker: 'ポーカー',
    refresh: 'リフレッシュ',
    somethingWentWrong: '問題が発生しました。再読み込みしてください',
    season: 'シーズン',
    unlocked: 'アンロック済み',
    activate: 'アクティベート',
    pause: '一時停止',
    activateSuccess: '正常にアクティベートされました',
    pauseSuccess: '正常に一時停止されました',
    redeemSuccess: '正常に利用されました',
    betPlaced: 'ベットが置かれました',
    betPlacedSuccess: 'ベットが処理中です。詳細については通知を確認してください',
    viewProgress: '進行状況を見る',
    inPlay: 'プレイ中',
    dinoCasinoLeaderboard: 'ダイノカジノリーダーボード',
    welcomeToDinoCasino: '다이노 카지노에 오신 것을 환영합니다!',
    registerYourAccount: '계정을 등록하세요!',
    myLevelProgress: 'マイレベルの進行状況',
    levelStatus: 'レベルステータス',
    notStarted: '試合はまだ開始されていません。',
    matchLive: '試合はライブです',
    matchSuspended: '試合は一時停止されました',
    ended: '試合は終了しました',
    matchClosed: '試合は終了しました',
    marketActive: '市場はアクティブです',
    marketDeactive: '市場は非アクティブです',
    marketSuspended: '市場は一時停止されました',
    marketSettled: '市場は確定しました',
    marketCancelled: '市場はキャンセルされました',
    outcomeDeactive: '結果は非アクティブです',
    outcomeActive: '結果はアクティブです',
    outcomeSettled: '結果は確定しました',
    outcomeSuspended: '結果は一時停止されました',
    outcomeCancelled: '結果はキャンセルされました',
    balance: '残高',
    remaining: '残り',
    referral: '紹介',
    half_won: '半分勝ち',
    half_lost: '半分負け',
    extendednetent: '拡張されたネットエント',
    depositWallet: '入金',
    withdrawalWallet: '出金',
    level: 'レベル',
    days: '日',
    hours: '時間',
    minutes: '分',
    seconds: '秒',
    dinoCasino: 'ディノカジノ',
    majorHiddenProgress: '重要な隠れたアップデート進行中',
    experienceSoon: 'もうすぐ体験できます！',
    depositableAmount: '入金可能額',
    withdrawableAmount: '出金可能額',
    cashoutMessage: 'キャッシュアウトが進行中です。詳細については通知を確認してください',
    cashoutInprogress: 'キャッシュアウト進行中',
    favoriteRemoved: 'お気に入りが正常に削除されました',
    favoriteAdded: 'お気に入りが正常に追加されました',
    maxCustomMarketLimit: '1つのベットスリップで最大10個のカスタムマーケットの組み合わせを選択できます',
    betErrorHotCombo: 'このホットコンボに対してベットを配置できません。別のホットコンボを試してください',
    paybackBonus: 'ペイバックボーナス',
    'PAYBACK-BONUS': 'ペイバック-ボーナス',
    transactionDeposit: 'デポジット',
    transactionWithdrawal: '引き出し',
    mustBeMultipleOf10000: '金額は10,000の倍数である必要があります。',
    min10000Required: '金額は10,000以上である必要があります。',
    maxAmountEnteredis: '最大金額は',
    requestErrorMessage: '出金依頼金額は、キャッシュウォレットの残高以下である必要があります。',
    topMatch: 'トップマッチ',
    eventStartIn: 'イベント開始まで',
    betNotPlacedPleaseTryAgain: 'このイベントに対してベットを行うことができません。もう一度お試しください。',
    topMatchesNotAvailable: 'トップマッチは利用できません。',
    goToEvent: 'イベントへ移動',
    hotCombo: 'ホットコンボ',
    liveMatchResult: 'ライブ試合結果'

  }
}

export default JAJSON

import { createContext, useState } from 'react'
import { SOCKET_EMMITER } from '../../../utils/constants'

export const SportsBookContext = createContext()

export const useSportsBookContextValues = () => {
  const sessionJoinedRooms = sessionStorage.getItem(SOCKET_EMMITER.SESSION_ROOMS) ? JSON.parse(sessionStorage.getItem(SOCKET_EMMITER.SESSION_ROOMS)) : null
  const [sportsSocketFixtureChangeData, setSportsFixtureChangeSocketData] = useState({})
  const [socket, setSocket] = useState(null)
  const [joindRooms, setJoinedRooms] = useState(sessionJoinedRooms || [])
  const [liveCustomEvents, setLiveCustomEvents] = useState({})
  const [topCustomEvents, setTopCustomEvents] = useState({})
  const [upcomingCustomEvents, setUpcomingCustomEvents] = useState({})
  const [singleCustomEvents, setSingleCustomEvents] = useState({})
  const [marketDetailsCustomEvents, setMarketDetailsCustomEvents] = useState({})
  const [allBets, addBet] = useState([])
  const [customBetData, setCustomBetData] = useState([])
  const [upcomingfavorites, setUpcomingfavoritesCustomEvents] = useState([])
  const [livefavorites, setLivefavoritesCustomEvents] = useState([])
  const [hotComboEvents, setHotComboEvents] = useState({})
  const [topLiveCustomEvents, setTopLiveCustomEvents] = useState([])

  return {
    setSportsFixtureChangeSocketData,
    sportsSocketFixtureChangeData,
    socket,
    setSocket,
    joindRooms,
    setJoinedRooms,
    liveCustomEvents,
    setLiveCustomEvents,
    marketDetailsCustomEvents,
    setMarketDetailsCustomEvents,
    singleCustomEvents,
    setSingleCustomEvents,
    upcomingCustomEvents,
    setUpcomingCustomEvents,
    topCustomEvents,
    setTopCustomEvents,
    customBetData,
    setCustomBetData,
    allBets,
    addBet,
    upcomingfavorites,
    setUpcomingfavoritesCustomEvents,
    livefavorites,
    setLivefavoritesCustomEvents,
    hotComboEvents,
    setHotComboEvents,
    setTopLiveCustomEvents,
    topLiveCustomEvents
  }
}
